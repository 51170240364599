@import './variables.scss';
@import './mixins.scss';
@import './animations.scss';

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: $purple;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $blue;
}


.title {
    text-align: center;
    position: relative;
    // color: #fff;
    font-family: $header-font;
    margin: auto;


    h4 {
        font-family: "Raleway", sans-serif;
        color: $purple;
        font-weight: bold;
        font-size: 60px;
        width: 100%;
        height: 100%;
        margin: 0;
        text-transform: capitalize;
        display: flex;
        margin-bottom: 5%;
        align-items: center;
        justify-content: center;
        text-shadow:
            -1px -1px 0 rgba(255, 255, 255, .7),
            1px -1px 0 rgba(255, 255, 255, .7),
            -1px 1px 0 rgba(255, 255, 255, .7),
            1px 1px 0 rgba(255, 255, 255, .7),
            -1px 2px 1px #a0a0a0,
            -2px 4px 2px #a0a0a0,
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        mix-blend-mode: lighten;
        animation: moveit 10s infinite ease-in-out forwards;
        -webkit-filter: blur(2px);
        pointer-events: none;
    }

    @keyframes moveit {
        0% {
            transform: translate(0%, 0%);
            filter: blur(2px);
        }

        15% {
            filter: blur(0);
        }

        50% {
            transform: translate(3%, 3%);
        }

        100% {
            filter: blur(2px);
        }
    }

    p {
        // text-transform: lowercase;
        font-size: 1.1em;
        display: none;
    }

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 130%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     width: 10%;
    //     height: 2px;
    //     border-radius: 100%;
    //     background: #000;
    // }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 130%;
    //     left: 56%;
    //     transform: translate(-50%, -50%);
    //     width: 10px;
    //     height: 10px;
    //     border-radius: 100%;
    //     background: #000;
    // }
}

p,
li,
a {
    font-family: $body-font;
}


li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
    // font-weight: bold;
}

* {
    font-family: $header-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}


header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3000;
    padding: 1.5% 2%;
    // background: transparent;
    // background: $gradient;
    background: $gradient;
    box-shadow: $box-shadow;

    .logo {
        width: 25%;

        h1 {
            // 
            color: #fff;
            margin-left: 10%;
            font-size: 50px;
            font-weight: bolder;

        }
    }

    .links {
        margin-left: auto;
        width: 50%;

        a {
            // color: #fff;
            padding: 2% 3%;
            color: #fff !important;
            font-size: 25px;
            font-weight: bold;
            cursor: default;
            margin-right: 3%;
            border-radius: 40px;

            &:hover {
                // font-size: 28px;
                background: #fff;
                color: $purple !important;
                transition: .5s all ease;
            }
        }
    }

    .menu {
        width: 40px;
        height: 40px;
        fill: #fff;
        display: none;
        margin-right: 10%;
    }

    #navbar-mobile {
        display: none;
        position: fixed;
        right: 0;
        top: 0;
        width: 60%;
        height: 100%;
        padding: 5%;
        background: $purple;
        box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
        animation: slide-in-right .5s 1;

        .links {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 30%;

            a {
                margin-bottom: 10%;
                font-size: 20px;
                color: #fff !important;
                padding-left: 0;
                font-weight: normal;
            }
        }

        .cross {
            width: 40px;
            height: 40px;
            padding: 2%;
            fill: #fff;
            margin-left: auto;
            position: absolute;
            top: 2%;
            right: 10%;
        }
    }

}

// banner =============================================================================
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;1,500&display=swap");



.geo-arrow {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-miterlimit: 10
}

.banner {
    background: linear-gradient($blue, $purple);
    height: 100vh;
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-family: "DM Mono", monospace;
    font-weight: 300;
    overflow: hidden;
    color: white;
    text-align: center;

    h1 {
        font-size: 3em;
        margin-bottom: 0.2em;
        margin-top: 7%;
    }

    p {
        width: 80%;
        margin: auto;
    }

    .main {
        height: 100vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        align-items: center;

        .main__text-wrapper {
            position: absolute;
            z-index: 4;

            .main__title {
                text-transform: capitalize;
            }
        }



        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            z-index: -3;
        }

        &:before {
            z-index: 1;
            right: 0;
            bottom: -19;
            height: 30em;
            width: 30em;
            border-radius: 30em;
            background: linear-gradient($blue, $purple);
            align-self: flex-end;
            animation: gradient-fade 8s ease-in-out 3s infinite alternate;
        }

        &:after {
            $circle-unit: 10em;
            top: 0;
            left: 30;
            z-index: 1;
            height: $circle-unit;
            width: $circle-unit;
            border-radius: $circle-unit;
            background: linear-gradient($blue, $purple);
            animation: gradient-fade-alt 6s ease-in-out 3s infinite alternate;
        }

        &__text-wrapper {
            position: relative;
            padding: 2em;

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
            }

            &:before {
                $circle-unit: 13em;
                z-index: 1;
                top: -3em;
                right: -3em;
                width: $circle-unit;
                height: $circle-unit;
                opacity: 0.7;
                border-radius: $circle-unit;
                background: linear-gradient($ltblue, $purple);
                animation: rotation 7s linear infinite;
            }

            &:after {
                $circle-unit: 20em;
                z-index: 1;
                bottom: -#{$circle-unit};
                width: $circle-unit;
                height: $circle-unit;
                border-radius: $circle-unit;
                background: linear-gradient($magenta, $purple);
                animation: rotation 7s linear infinite;
            }
        }
    }

    .arrow {
        z-index: 1000;
        opacity: 0.5;
        position: absolute;

        &--top {
            top: 0;
            left: -5em;
        }

        &--bottom {
            bottom: 0;
            right: 3em;
        }
    }

    .circle {
        transform: translate(50%, -50%) rotate(0deg);
        transform-origin: center;

        &--ltblue {
            $circle-unit: 20em;
            height: $circle-unit;
            width: $circle-unit;
            border-radius: $circle-unit;
            background: linear-gradient($ltblue, $blue);
        }
    }

    .backdrop {
        position: absolute;
        width: 100vw;
        height: 100vh;
        display: block;
        background-color: pink;
    }

    .dotted-circle {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.3;
        animation: rotation 38s linear infinite;
    }

    // animations
    .draw-in {
        stroke-dasharray: 1000;
        stroke-dashoffset: 10;
        animation: draw 15s ease-in-out alternate infinite;
    }

    @keyframes draw {
        from {
            stroke-dashoffset: 1000;
        }

        to {
            stroke-dashoffset: 0;
        }
    }

    .item-to {
        animation-duration: 10s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
    }

    .bounce-1 {
        animation-name: bounce-1;
        animation-timing-function: ease;
    }

    .bounce-2 {
        animation-name: bounce-2;
        animation-timing-function: ease;
    }

    .bounce-3 {
        animation-name: bounce-3;
        animation-timing-function: ease;
    }

    @keyframes bounce-1 {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(50px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes bounce-2 {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-30px);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes bounce-3 {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(30px);
        }

        100% {
            transform: translateY(0);
        }
    }

    // gradient fade

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @keyframes gradient-fade {
        from {
            transform: translate(10%, -10%) rotate(0deg);
        }

        to {
            transform: translate(50%, -50%) rotate(360deg);
        }
    }

    @keyframes gradient-fade-alt {
        from {
            transform: translate(-20%, 20%) rotate(0deg);
        }

        to {
            transform: translate(-60%, 60%) rotate(360deg);
        }
    }

}

// banner ends ======================================================================

// mechanism==========================================================================

.mechanism {
    .m-body {

        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            position: absolute;
            top: 90%;
            left: 43%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;

            // box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
            svg {
                width: 40px;
                height: 40px;
                // fill: $color-primary;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: $color-primary;
                border-radius: 10px;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);

                path {
                    fill: #fff;
                }
            }
        }

        .alice-carousel__prev-btn {
            transform: scaleX(-1);
            left: 44%;
            top: 77%;
            margin-top: -1%;
        }


        .__target {
            .m-card {
                .m-card-left {
                    animation: slide-in-up 1s 1;
                }

                .m-card-right {
                    animation: slide-in-down 1s 1;
                }
            }
        }

        .m-card {
            width: 80%;
            margin: auto;
            display: flex;

            .m-card-left {
                width: 60%;
                --p: 180px;
                /* control the shape (can be percentage) */
                height: 380px;
                aspect-ratio: 1;
                clip-path: polygon(0 0, 100% 0, calc(100% - var(--p)) 100%, 0 100%);
                background: #C44D58;
                animation: slide-in-left 1s 1;

                img {
                    width: 100%;
                    height: 100%;
                    filter: drop-shadow(2px 2px 5px rgba($color: #000, $alpha: 0.3));
                }
            }

            .m-card-right {
                width: 60%;
                margin-top: 5%;
                --p: 180px;
                /* control the shape (can be percentage) */
                height: 380px;
                aspect-ratio: 1;
                clip-path: polygon(var(--p) 0, 100% 0, 100% 100%, 0 100%);
                background: $gradient;
                margin-left: -20%;
                padding: 5% 5% 5% 15%;
                text-align: right;
                color: #fff;
                animation: slide-in-right 1s 1;

                h4 {
                    font-size: 30px;
                    text-transform: capitalize;
                    margin-bottom: 30px;
                }

                p {
                    line-height: 180%;
                    color: rgba($color: #fff, $alpha: 0.7);
                }
            }
        }

    }

}

// mechanism ends ===================================================================

// indications=========================================================================
.indications {
    padding: 5% 2%;

    .indi {
        color: $purple;
        // text-transform: capitalize;
        font-size: 20px;
        font-weight: bold;
    }

    .indications-body {
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: relative;
        z-index: 2;

        .indications-img {
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: stretch;
            // flex-wrap: wrap;

            .img {
                overflow: hidden;
                width: calc(100% / 7);
                border-radius: 10px;
                margin: 5px;
                box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
                height: 45vh;

            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: cover;

                &:hover {
                    transform: scale(1.2);
                    filter: brightness(110%);
                }
            }
        }

        .animated-content {
            animation: slide-in-up 0.5s ease-in-out;
        }

        .indications-right {
            width: 30%;
            padding: 20px 40px;
            background: linear-gradient($blue, $purple);

            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 10px;
            margin: 10px;
            text-align: center;
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);

            h3 {
                font-size: 30px;
                margin-bottom: 20px;
                text-transform: capitalize;
            }

            p {
                line-height: 130%;
                color: rgba($color: #fff, $alpha: 0.8);
                font-size: 16px;
                margin: 0;
            }
        }
    }
}

// indications ends ===================================================================

// .banner {
//     position: relative;
//     background: $gradient;
//     display: flex;
//     flex-direction: row-reverse;
//     padding: 4% 0;
//     // margin-top: 5%;
//     background: url("../Image/bg.png");
//     background-repeat: no-repeat;
//     background-size: cover;
//     height: 100vh;


//     img {
//         width: 40%;
//         object-fit: contain;
//         position: relative;
//         z-index: 2;
//         margin-right: 5%;
//         margin-top: -5%;
//         filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
//         display: none;
//     }

//     .banner-card {
//         width: 40%;
//         position: absolute;
//         top: 55%;
//         left: 30%;
//         transform: translate(-50%, -50%);
//         text-shadow: $text-shadow;


//         h2 {
//             font-family: $header-font;
//             margin-bottom: 2%;
//             font-size: 50px;
//             text-transform: capitalize;
//             font-weight: bold;
//         }

//         p {
//             font-size: 18px;
//             // text-transform: capitalize;
//             margin-bottom: 7%;
//             // margin-left: 5%;
//             line-height: 180%;
//             // text-align: center;
//             text-shadow: none;
//         }


//         a {

//             font-size: 20px;
//             font-weight: 700;
//             line-height: 1.2em;
//             color: #FFFFFF;
//             background-color: $color-primary;
//             box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.5);
//             border-radius: 8px;
//             padding: 2% 5%;
//             letter-spacing: 1px;
//             cursor: default;
//             // margin-left: 5%;
//         }
//     }
// }

// section {
//     padding: 3% 5%;
//     // background: #fff;

//     &:nth-child(even) {
//         // background: rgba($color: $orange, $alpha: 1);
//         // background: $gradient;
//         /* HTML: <div class="inverted-radius"></div> */
//         --r: 25px;
//         /* the radius */
//         --s: 40px;
//         /* the size of the corner*/
//         border-radius: var(--r);
//         --_m: /calc(2*var(--r)) calc(2*var(--r)) radial-gradient(#000 70%, #0000 72%) no-repeat;
//         mask:
//             right calc(var(--s) + var(--r)) top 0 var(--_m),
//             right calc(var(--s) + var(--r)) var(--_m),
//             radial-gradient(var(--s) at 100% 0, #0000 99%, #000 101%) calc(-1*var(--r)) var(--r) no-repeat,
//             conic-gradient(at calc(100% - var(--s) - 2*var(--r)) calc(var(--s) + 2*var(--r)),
//                 #0000 25%, #000 0);

//         .title {
//             color: #fff;

//             &::before,
//             &::after {
//                 background: #fff;
//             }
//         }
//     }
// }

section {
    padding: 5%;

    &:nth-child(even) {
        background: rgba($color: #e0e0e0, $alpha: 0.3);
    }
}

.akcit {
    overflow: hidden;

    .about {
        position: relative;

        .about-body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            z-index: 2;

            @keyframes rotate {
                50% {
                    transform: rotate(180deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }

            .about-shape {
                position: absolute;
                top: -8%;
                left: 5%;
                z-index: 1;
                height: 25em;
                width: 25em;
                border-radius: 20%;
                background: linear-gradient(#3800e7, #8a15ff);
                // animation: gradient-fade-alt 6s ease-in-out 3s infinite alternate;
                animation: rotate 8s infinite;
                rotate: 45deg;
            }

            img {
                width: 40%;
                object-fit: contain;
                position: relative;
                z-index: 2;
                filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
            }

            .about-text {
                width: 60%;
                padding-left: 5%;

                h3 {
                    color: $orange;
                    font-weight: bold;
                    font-size: 40px;
                    margin-bottom: 3%;
                    // letter-spacing: 2px;
                    font-family: $header-font;
                }

                .tagline {
                    text-transform: capitalize;
                    margin-bottom: 2%;
                    font-size: 18px;
                    font-style: italic;
                    font-family: $header-font;
                }

                p {
                    line-height: 170%;
                    font-size: 16px;
                    margin-bottom: 5%;
                    color: rgba($color: #000, $alpha: 0.7);
                }

                a {
                    font-size: 18px;
                    font-weight: 700;
                    color: #fff;
                    background: $gradient;
                    // box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.8);
                    border-radius: 8px;
                    padding: 2% 8%;
                    cursor: default;
                    font-family: $body-font;
                }

            }


        }
    }

    .salt {
        position: relative;
        background: url("../Image/salt.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 85vh;
        background-position: 10% 50%;

        .salt-body {
            // background: rgba($color: #fff, $alpha: 0.6);
            padding: 2%;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            margin: auto;


            .curve {
                display: none;
            }

            // .salt-img {

            //     position: relative;
            //     width: 30%;

            //     &::after {
            //         content: "";
            //         position: absolute;
            //         top: 2%;
            //         left: 2%;
            //         // transform: translate(-50%, -50%);
            //         height: 25em;
            //         width: 25em;
            //         border-radius: 20%;
            //         background: linear-gradient(#3800e7, #8a15ff);
            //         // animation: gradient-fade-alt 6s ease-in-out 3s infinite alternate;
            //         animation: rotate 8s infinite;
            //         rotate: 45deg;
            //         z-index: 1;
            //     }

            //     img {
            //         object-fit: cover;
            //         width: 100%;
            //         aspect-ratio: 1;
            //         clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
            //         position: relative;
            //         z-index: 2;
            //         --r: 30px;
            //         margin: calc(tan(22.5deg)*var(--r));
            //         clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%) margin-box;
            //         --_g: /calc(2*var(--r)) calc(2*var(--r)) radial-gradient(#000 70%, #0000 72%);
            //         --_s: calc(100% - (1 - tan(22.5deg))*var(--r));
            //         mask:
            //             conic-gradient(#000 0 0) no-repeat 50%/var(--_s) var(--_s),
            //             top var(--_g) no-repeat space, left var(--_g) space no-repeat;
            //     }
            // }

            .salt-text {

                p {

                    font-size: 25px;
                    text-align: center;
                }
            }


        }
    }

    .dosage {
        .dosage-body {
            display: flex;
            // justify-content: center;
            // align-items: stretch;

            .dosage-card {
                width: calc(100% /4);
                border-radius: 10px;
                // box-shadow: $box-shadow;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-right: 20px;
                background: transparent;
                // --mask: radial-gradient(40px at 40px 40px, #0000 98%, #000) -40px -40px;
                // -webkit-mask: var(--mask);
                // mask: var(--mask);
                color: #fff;
                // margin-top: 3%;
                background: linear-gradient($ltblue, $purple);
                padding: 6% 1% 3% 1%;
                --c: 85;
                aspect-ratio: 3/2;
                //   background: #a7237b;
                mask: radial-gradient(calc(var(--c)*1%) 100% at 50% calc(-100%*cos(asin(50/var(--c)))), #0000 calc(100% - 1px), #000);
                clip-path: ellipse(calc(var(--c)*1%) 100% at top);

                &:hover {
                    background: linear-gradient($blue, $purple);

                    svg {
                        transform: scale(1.2);
                    }
                }

                // &:first-child {
                //     margin-left: 10%;
                // }

                // &:nth-child(even) {
                //     margin-top: 10%;
                //     margin-left: -5%;
                // }

                // &:nth-child(odd) {
                //     margin-bottom: 10%;
                // }

                // &:nth-child(3) {
                //     margin-left: -5%;
                // }

                svg {
                    width: 70px;
                    height: 70px;
                    margin: auto;
                    fill: #fff;
                }

                img {
                    width: 100%;
                    height: 25vh;
                    object-fit: cover;
                    border-radius: 10px 10px 0 0;
                }

                p {
                    font-size: 16px;
                    text-align: center;
                    flex-grow: 1;
                    margin-top: 5%;
                    margin-bottom: 0;
                    width: 80%;
                }
            }

        }
    }

    .safety {
        .safety-body {
            display: flex;
            justify-content: center;
            align-items: stretch;
            // flex-wrap: wrap;
            position: relative;
            z-index: 2;

            .curve {
                background: $orange;
            }

            .safety-card {
                width: calc(100% / 4);
                margin-right: 1%;
                margin-bottom: 1%;
                // background: #fff;
                border-radius: 10px;

                .safety-card-text {
                    // width: 0%;
                    margin-top: 5%;
                    --s: .5em;
                    /* triangle size */
                    --p: 20%;
                    /* triangle position (0%:left 100%:right) */
                    --r: 1em;
                    /* the radius */
                    // height: 30vh;

                    padding: 1em;
                    border: var(--s) solid #0000;
                    border-radius: calc(var(--r) + var(--s));
                    background: border-box $gradient;
                    /* the coloration */
                    --_m: 0/var(--s) var(--s) no-repeat;
                    --_p: clamp(var(--r) + 3*var(--s)/2, var(--p), 100% - var(--r) - 3*var(--s)/2);
                    -webkit-mask:
                        linear-gradient(#000 0 0) padding-box,
                        radial-gradient(100% 100% at 100% 0, #0000 99%, #000 102%) calc(var(--_p) + var(--s)/2) var(--_m),
                        radial-gradient(100% 100% at 0 0, #0000 99%, #000 102%) calc(var(--_p) - var(--s)/2) var(--_m);



                    h3 {
                        font-size: 20px;
                        font-weight: 500;
                        margin: 0;
                        margin-bottom: 10px;

                        color: #fff;
                        text-transform: capitalize;
                    }


                    p {
                        color: rgba($color: #fff, $alpha: 0.9);
                        font-size: 16px;
                        line-height: 130%;
                        margin: 0;
                    }
                }

                svg {
                    width: 80px;
                    height: 80px;
                    margin-left: 5%;
                }
            }
        }
    }

    .buy {

        .buy-body {
            display: flex;

            img {
                width: 30%;
                margin-top: -3%;
                object-fit: contain;
                filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.4));
            }

            form {
                width: 70%;
                display: flex;
                flex-direction: column;
                padding-left: 5%;

                input,
                select {
                    border: none;
                    border-bottom: 1px solid $color-primary;
                    width: calc(100% / 2.2);
                    padding: 20px;
                    margin-right: 4%;
                    font-size: 16px;
                    margin-bottom: 2%;
                    border-radius: 0;
                    outline: none;
                    box-shadow: $box-shadow;
                    border-radius: 10px;

                    &:nth-child(even) {
                        margin-right: 0;
                    }
                }

                select {
                    width: 95%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    background: transparent;
                    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                    background-repeat: no-repeat;
                    background-position-x: 98%;
                    background-position-y: 20px;
                    border: 1px solid #dfdfdf;
                }

                button {
                    width: 96%;
                    background: $gradient;
                    color: #fff;
                    border: none;
                    padding: 20px;
                    font-size: 16px;

                    box-shadow: $box-shadow;
                    border-radius: 10px;
                }
            }
        }
    }
}


footer {
    background: $gradient;
    padding: 5% 10% 0 10%;
    color: #fff;

    .footer-top {

        display: flex;
        justify-content: space-between;

        .footer-subscribe {
            width: 50%;

            h3 {
                font-size: 20px;
                text-transform: capitalize;
                font-family: $header-font;
            }

            p {
                font-size: 16px;

            }

            .subscribe {
                margin-top: 5%;
                display: flex;


                input {
                    background: transparent;
                    padding: 1% 2%;
                    border: 1px solid #fff;
                    border-radius: 4px;
                    width: 80%;
                    margin-right: 3%;

                    &::placeholder {
                        color: #fff;
                    }
                }

                button {
                    background: #fff;
                    color: #000;
                    padding: 2%;
                    width: 20%;
                    border: none;
                    border-radius: 4px;

                }
            }

            .footer-follow {
                margin-top: 5%;

                a {
                    margin-right: 3%;

                    svg {
                        width: 30px;
                        height: 30px;

                        path {
                            fill: #fff;
                        }
                    }
                }

            }
        }


        .footer-contact {
            width: 30%;

            h4 {
                // font-weight: bold;
                font-size: 25px;
                margin-bottom: 5%;
            }

            ul {
                padding: 0;

                li {

                    font-size: 18px;
                    // font-weight: 100;
                    line-height: 200%;
                    display: flex;
                    align-items: baseline;

                    .svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 5%;

                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #fff;
                    }

                    a {
                        text-decoration: none;

                        font-size: 18px;
                    }
                }
            }
        }
    }

    .footer-copyright {
        border-top: 1px solid rgba($color: #fff, $alpha: 0.6);
        margin-top: 5%;
        padding: 2%;
        display: flex;


        .copy {
            margin-left: auto;
            width: 30%;

            a {
                border-right: 1px solid #000;

                background: #fff;
                color: #000;
                padding: 2% 4%;
                border-radius: 4px;
                text-align: center;

                &:last-child {
                    border-right: 0;
                    // padding-left: 2%;
                }
            }
        }
    }

}


/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) {

    p,
    a,
    li {
        font-size: 16px;
    }

    .title {
        h4 {
            font-size: 30px;
        }

    }

    header {
        padding: 5% 0;

        .logo {
            width: 60%;

            h1 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .banner {

        .main__text-wrapper:before {
            z-index: -1;
        }

        .main__text-wrapper {
            padding: 1em;
        }

        h1 {
            font-size: 2.7em;
        }

        p {
            width: 100%;
            // margin-top: 15%;
        }

        .arrow {
            height: 100px;
        }

        .arrow--top,
        .dotted-circle {
            display: none;
        }
    }

    .akcit {
        .about {
            .about-body {
                flex-direction: column;

                .about-shape {
                    width: 19em;
                    height: 15em;
                    animation: none;
                    top: 3%;
                    rotate: 5deg;
                    border-radius: 30px;
                }

                img {
                    margin-top: 5%;
                }

                img,
                .about-text {
                    width: 100%;
                }

                .about-text {
                    margin-top: 15%;
                    margin-bottom: 5%;

                    p {
                        font-size: 16px;
                    }

                    a {
                        text-align: center;
                        font-size: 20px;
                    }


                }
            }
        }

        .indications {

            .indications-body {
                // flex-direction: column;
                flex-wrap: wrap;

                .indications-img {
                    width: 100%;
                    flex-wrap: wrap;

                    .img {
                        width: calc(100% / 4.5);
                        height: 17vh;
                    }
                }

                .indications-right {
                    width: 100%;
                    padding: 20px;

                    h3 {
                        font-size: 20px;
                    }

                }
            }
        }

        .mechanism {
            .m-body {


                .alice-carousel__prev-btn svg {
                    top: -500%;
                    left: -55%;
                }

                .alice-carousel__next-btn svg {
                    top: -500%;
                    left: 155%;
                }

                .m-card {
                    width: 100%;
                    flex-direction: column;



                    .m-card-left {
                        height: 200px;
                        width: 100%;

                        img {
                            object-fit: cover;
                        }
                    }

                    .m-card-right {
                        width: 100%;
                        margin-top: -10%;
                        height: 300px;
                        margin-left: 0%;
                        clip-path: none;
                        // z-index: 3;
                        padding: 20% 5% 5% 5%;

                        h4 {
                            font-size: 20px;
                            text-align: center;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 140%;
                            text-align: left;

                        }
                    }
                }
            }
        }

        .salt {
            height: 40vh;

            .salt-body {
                flex-direction: column;
                background-position: 10% 40%;
                width: 100%;
                margin-left: auto;

                .salt-text {
                    background: rgba($color: #fff, $alpha: 0.4);
                    backdrop-filter: blur(2px);
                    padding: 10px 20px;
                    border-radius: 20px;

                    p {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                }
            }
        }

        .dosage {
            .dosage-body {
                flex-direction: column;

                .dosage-card {
                    width: 100%;

                    svg {
                        margin-top: 12%;
                    }
                }
            }
        }

        .safety {
            .safety-body {
                flex-direction: column;

                .safety-card {
                    width: 100%;
                    margin-top: 5%;
                }
            }
        }

        .buy {
            .buy-body {
                flex-direction: column;

                img {
                    display: none;
                }

                form {
                    width: 100%;
                    margin-top: 5%;

                    input,
                    select {
                        width: 100%;
                        padding: 10px 20px;
                        margin-bottom: 5%;
                    }
                }
            }
        }

    }

    footer {
        padding: 5%;

        .footer-top {
            flex-direction: column;

            .footer-subscribe,
            .footer-about,
            .footer-contact {
                width: 100%;
            }

            .footer-subscribe {
                .footer-follow {
                    margin-top: 5%;
                    margin-bottom: 5%;
                }

                .subscribe {
                    input {
                        width: 70%;
                    }

                    button {
                        width: 30%;
                    }
                }

                p {
                    font-size: 16px;
                }
            }

            .footer-about {
                margin-left: 0;
                padding-left: 0;
                margin-top: 5%;
            }

            .footer-contact {
                margin-top: 5%;

                ul {
                    li {
                        font-size: 16px;

                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .footer-copyright {
            flex-direction: column;

            p {
                margin-bottom: 2%;
            }

            .copy {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    p,
    a,
    li {
        font-size: 16px;
    }

    .title {
        h4 {
            font-size: 40px;
        }

    }

    header {
        padding: 5% 0;

        .logo {
            width: 60%;

            h1 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .banner {
        .main__text-wrapper:before {
            z-index: -1;
        }

        .arrow {
            height: 100px;
        }

        .arrow--top,
        .dotted-circle {
            display: none;
        }

        .main__text-wrapper {
            padding: 1em;
        }

        h1 {
            font-size: 2.7em;
        }

        p {
            width: 100%;
            // margin-top: 15%;
        }
    }

    .akcit {
        .about {
            .about-body {
                flex-direction: column;

                .about-shape {
                    width: 22em;
                    height: 15em;
                    animation: none;
                    top: 3%;
                    rotate: 5deg;
                    border-radius: 30px;
                }

                img {
                    margin-top: 5%;
                }

                img,
                .about-text {
                    width: 100%;
                }

                .about-text {
                    margin-top: 5%;

                    p {
                        font-size: 16px;
                    }

                    a {
                        text-align: center;
                        font-size: 16px;
                    }


                }
            }
        }

        .indications {

            .indications-body {
                // flex-direction: column;
                flex-wrap: wrap;

                .indications-img {
                    width: 100%;
                    flex-wrap: wrap;

                    .img {
                        width: calc(100% / 4.5);
                        height: 17vh;
                    }
                }

                .indications-right {
                    width: 100%;
                    padding: 20px;

                }
            }
        }

        .mechanism {
            .m-body {


                .alice-carousel__prev-btn svg {
                    top: -500%;
                    left: -205%;
                }

                .alice-carousel__next-btn svg {
                    top: -500%;
                    left: 300%;
                }

                .m-card {
                    width: 100%;
                    flex-direction: column;



                    .m-card-left {
                        height: 250px;
                        width: 80%;

                        img {
                            object-fit: cover;
                        }
                    }

                    .m-card-right {
                        width: 100%;
                        margin-top: -10%;
                        height: 280px;
                        margin-left: 0%;
                        clip-path: none;
                        // z-index: 3;
                        padding: 15% 5% 5% 5%;

                        h4 {
                            font-size: 20px;
                            text-align: center;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 140%;
                            text-align: left;

                        }
                    }
                }
            }
        }

        .salt {
            height: 60vh;

            .salt-body {
                flex-direction: column;
                background-position: 10% 40%;
                width: 100%;
                margin-left: auto;

                .salt-text {
                    background: rgba($color: #fff, $alpha: 0.4);
                    backdrop-filter: blur(2px);
                    padding: 10px 20px;
                    border-radius: 20px;

                    p {
                        // font-size: 20px;
                        margin-bottom: 10px;
                    }

                }
            }
        }

        .dosage {
            .dosage-body {
                flex-wrap: wrap;

                .dosage-card {
                    width: calc(100% / 2.2);

                    svg {
                        margin-top: 12%;
                    }
                }
            }
        }

        .safety {
            .safety-body {
                flex-direction: column;

                .safety-card {
                    width: 100%;
                    margin-top: 5%;
                }
            }
        }

        .buy {
            .buy-body {
                flex-direction: column;

                img {
                    display: none;
                }

                form {
                    width: 100%;
                    margin-top: 5%;

                    input,
                    select {
                        // width: 100%;
                        // padding: 10px 20px;
                        margin-bottom: 5%;
                    }
                }
            }
        }

    }

    footer {
        .footer-top {
            flex-direction: column;

            .footer-subscribe,
            .footer-about,
            .footer-contact {
                width: 100%;
            }

            .footer-subscribe {
                .footer-follow {
                    margin-top: 5%;
                    margin-bottom: 5%;
                }

                .subscribe {
                    input {
                        width: 70%;
                    }

                    button {
                        width: 30%;
                    }
                }

                p {
                    font-size: 18px;
                }
            }

            .footer-about {
                margin-left: 0;
                padding-left: 0;
                margin-top: 5%;
            }

            .footer-contact {
                // margin-top: 5%;

                ul {
                    li {
                        font-size: 18px;

                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .footer-copyright {
            flex-direction: column;

            p {
                margin-bottom: 2%;
            }

            .copy {
                margin-left: 0;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    p,
    a,
    li {
        font-size: 16px;
    }

    .title {
        h4 {
            font-size: 40px;
        }

    }

    header {
        padding: 5% 0;

        .logo {
            width: 60%;

            h1 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }

    .banner {
        .main__text-wrapper:before {
            z-index: -1;
        }

        .arrow {
            height: 100px;
        }

        .arrow--top,
        .dotted-circle {
            display: none;
        }

        .main__text-wrapper {
            padding: 1em;
        }

        h1 {
            font-size: 2.7em;
        }

        p {
            width: 100%;
            // margin-top: 15%;
        }
    }

    .akcit {
        .about {
            .about-body {
                flex-direction: column;

                .about-shape {
                    width: 22em;
                    height: 15em;
                    animation: none;
                    top: 3%;
                    rotate: 5deg;
                    border-radius: 30px;
                }

                img {
                    margin-top: 5%;
                }

                img,
                .about-text {
                    width: 100%;
                }

                .about-text {
                    margin-top: 5%;

                    p {
                        font-size: 16px;
                    }

                    a {
                        text-align: center;
                        font-size: 16px;
                    }


                }
            }
        }

        .indications {

            .indications-body {
                // flex-direction: column;
                flex-wrap: wrap;

                .indications-img {
                    width: 100%;
                    flex-wrap: wrap;

                    .img {
                        width: calc(100% / 4.5);
                        height: 17vh;
                    }
                }

                .indications-right {
                    width: 100%;
                    padding: 20px;

                }
            }
        }

        .mechanism {
            .m-body {


                .alice-carousel__prev-btn svg {
                    top: -500%;
                    left: -205%;
                }

                .alice-carousel__next-btn svg {
                    top: -500%;
                    left: 300%;
                }

                .m-card {
                    width: 100%;
                    flex-direction: column;



                    .m-card-left {
                        height: 250px;
                        width: 80%;

                        img {
                            object-fit: cover;
                        }
                    }

                    .m-card-right {
                        width: 100%;
                        margin-top: -10%;
                        height: 280px;
                        margin-left: 0%;
                        clip-path: none;
                        // z-index: 3;
                        padding: 15% 5% 5% 5%;

                        h4 {
                            font-size: 20px;
                            text-align: center;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 140%;
                            text-align: left;

                        }
                    }
                }
            }
        }

        .salt {
            height: 50vh;

            .salt-body {
                flex-direction: column;
                background-position: 10% 40%;
                width: 100%;
                margin-left: auto;

                .salt-text {
                    background: rgba($color: #fff, $alpha: 0.4);
                    backdrop-filter: blur(2px);
                    padding: 10px 20px;
                    border-radius: 20px;

                    p {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                }
            }
        }

        .dosage {
            .dosage-body {
                flex-wrap: wrap;

                .dosage-card {
                    width: calc(100% / 2.2);

                    svg {
                        margin-top: 12%;
                    }
                }
            }
        }

        .safety {
            .safety-body {
                flex-direction: column;

                .safety-card {
                    width: 100%;
                    margin-top: 5%;
                }
            }
        }

        .buy {
            .buy-body {
                flex-direction: column;

                img {
                    display: none;
                }

                form {
                    width: 100%;
                    margin-top: 5%;

                    input,
                    select {
                        // width: 100%;
                        // padding: 10px 20px;
                        margin-bottom: 5%;
                    }
                }
            }
        }

    }

    footer {
        .footer-top {
            flex-direction: column;

            .footer-subscribe,
            .footer-about,
            .footer-contact {
                width: 100%;
            }

            .footer-subscribe {
                .footer-follow {
                    margin-top: 5%;
                    margin-bottom: 5%;
                }

                .subscribe {
                    input {
                        width: 70%;
                    }

                    button {
                        width: 30%;
                    }
                }

                p {
                    font-size: 16px;
                }
            }

            .footer-about {
                margin-left: 0;
                padding-left: 0;
                margin-top: 5%;
            }

            .footer-contact {
                margin-top: 5%;

                ul {
                    li {
                        font-size: 16px;

                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .footer-copyright {
            flex-direction: column;

            p {
                margin-bottom: 2%;
            }

            .copy {
                margin-left: 0;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) {

    p,
    a,
    li {
        font-size: 16px;
    }

    header {
        padding: 3% 0;

        .logo {
            width: 60%;

            h1 {
                font-size: 35px;
                margin-bottom: 0;
            }
        }

        .links {
            display: none;
        }

        .menu {
            display: block;
        }
    }


    .akcit {
        .about {
            .about-body {
                .about-shape {
                    height: 18em;
                    width: 18em;
                    top: 8%;
                }
            }
        }

        .mechanism {
            .m-body {
                .m-card {
                    width: 100%;
                }
            }
        }

        .salt {
            .salt-body {
                .salt-img {
                    margin-left: -10%;

                    &::after {
                        width: 17em;
                        height: 17em;
                    }
                }

                .salt-text {
                    margin-left: 10%;
                }
            }
        }

        .dosage {
            .dosage-body {
                .dosage-card {
                    svg {
                        margin-top: 10%;
                    }
                }
            }
        }

        .indications {
            .indications-body {
                .indications-right {
                    padding: 20px;

                    h3 {
                        font-size: 25px;
                    }
                }
            }
        }

    }

    footer {
        .footer-top {

            .footer-subscribe {

                .subscribe {
                    input {
                        width: 70%;
                    }

                    button {
                        width: 30%;
                    }
                }

            }

        }

    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .akcit {
        .about {
            .about-body {
                .about-shape {
                    height: 20em;
                    width: 20em;
                }
            }
        }

        .mechanism {
            .m-body {
                .m-card {
                    width: 100%;
                }
            }
        }

        .salt {
            .salt-body {
                .salt-img {
                    &::after {
                        width: 20em;
                        height: 20em;
                    }
                }
            }
        }
    }
}