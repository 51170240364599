@import 'variables.scss';
@import 'mixins.scss';
@import 'animations.scss';

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

* {
    transition: .5s all ease;
    scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# Privacy Policy
--------------------------------------------------------------*/
.privacy-policy {
    margin-top: 5%;
    padding: 5%;
    color: $purple;

    h3 {
        font-size: 25px;
        font-weight: bold;
        margin-top: 3%;
        margin-bottom: 2%;
    }

    p {
        font-size: 16px;
        line-height: 150%;
        color: #000;
    }

    ol {
        padding-left: 5%;

        li {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 2%;
            list-style-type: unset;
            font-weight: bold;
            color: #000;
            // margin-top: 3%;
            color: $purple;
        }
    }

    ul {

        // padding-left: 0;
        li {
            font-size: 16px;
            margin-top: 0;
            margin: 1% 0;
            line-height: 160%;
            list-style-type: circle;
            font-weight: normal;
            color: #000;
        }
    }

}


/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) {

    .privacy-policy {
        margin-top: 20%;

        ol {
            padding-left: 5%;

            li {
                margin-top: 10%;
            }
        }

        ul {
            padding-left: 4%;
            margin-top: 5%;

            li {
                margin-top: 5%;
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {


    .privacy-policy {
        margin-top: 15%;

        ol {
            padding-left: 2.5%;

            li {
                margin-top: 5%;
            }
        }

        ul {
            padding-left: 4%;
            margin-top: 3%;

            li {
                margin-top: 0;
            }
        }
    }


}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {


    .privacy-policy {
        margin-top: 15%;

        ol {
            padding-left: 5%;
        }

        ul {
            padding-left: 4%;
            margin-top: 5%;
        }
    }

}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {


    .privacy-policy {
        margin-top: 15%;
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {

    .privacy-policy {
        margin-top: 15%;

    }
}

@media (min-width: 1025px) and (max-width: $xl) {}