@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Imperial+Script&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
$color-primary: #5F527D;
// $orange: #9a87c8;
$orange: #713da5;

$purple: #8a15ff;
$blue: #3800e7;
$ltblue: #15e0ff;
$magenta: #d000c5;

$header-font: "Poppins", sans-serif;
$body-font: "Public Sans", sans-serif;
$title-font: "Imperial Script", cursive;

// shadows
$box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
$text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

// gradient
$gradient: linear-gradient(313deg, $blue 0%, $purple 79%);
// padding-margin
$padding: 0 5% 5% 5%;

// transition
$transition: .5s all ease;